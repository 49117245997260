import { CheckboxInput, SummaryRow, TextAreaInput } from 'auditComponents';
import dotProp from 'dot-prop-immutable';
import _ from 'lodashExtended';
import { useReducer } from 'react';
import { connect } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ModalRoute } from 'react-router-modal';

import { updateSectionData } from '../../sections/operations';
import { getSectionData } from '../../sections/selectors';
import { selectRequiredBinGroupsForSectionId, updateBgSurvey } from '../../wasteAudit/selectors';
import Section from '../Section';
import SubmitSummarySection from '../SubmitSummarySection';
import { CustomSummaryRow, SubmitSummaryRowWithValidation } from './PAASummaryRow';
import { OtherWasteProducedModal } from './Sec4OtherWasteProduced/OtherWasteProducedModal';
import { OtherWasteProducedPerBinGroup } from './Sec4OtherWasteProduced/OtherWasteProducedPerBinGroup';

const SECTION_ID = "other_waste_produced_at_this_premises";
const TITLE = "Other Waste Produced at this Premises";

const Checkbox = CheckboxInput.Checkbox;

const d = dotProp;

const validateIntFieldGTE =
  (min) =>
  ({ fieldName, errorObject, updatedAttrs }) => {
    if (fieldName in updatedAttrs) {
      return parseInt(updatedAttrs[fieldName]) >= min
        ? null
        : [`please enter a value ${min} or greater`];
    }
    return null;
  };

const validateFields = (bgSurveyIdAndUpdatedAttrs) => {
  let errors = {};

  for (const [bgSurveyId, updatedAttrs] of Object.entries(
    bgSurveyIdAndUpdatedAttrs
  )) {
    errors[bgSurveyId] = errors[bgSurveyId] || {};

    errors[bgSurveyId]["totalKg"] = validateIntFieldGTE(1)({
      fieldName: "totalKg",
      updatedAttrs,
    });
  }

  return errors;
};

const incompleteMessage = (state) => {
  const sectionData = getSectionData(SECTION_ID, state);
  const binGroupSurveys =
    selectRequiredBinGroupsForSectionId(SECTION_ID)(state);
  const { packingNotesOrComment } = sectionData;

  const toCheck = [
    // packingNotesOrComment,
    ...binGroupSurveys.flatMap((bgSurvey) => {
      const {
        totalKg,
        hasUsedDefaultPackaging,
        hasUsedOtherWastePackaging,
        otherWastePackagingUsedFreeText,

        isProduced,
        askIsProduced,
      } = bgSurvey;

      // NOTE: skip validation for this if its optional (eg. Recycling Waste)
      if (askIsProduced && isProduced != "yes") {
        return [];
      }

      return [
        // We want to mark this as incomplete if user doesn't enter any value and this becomes 0
        parseInt(totalKg) === 0 || totalKg == null
          ? ""
          : "consider it completed",

        // must satisfy one
        hasUsedDefaultPackaging ||
          (hasUsedOtherWastePackaging && otherWastePackagingUsedFreeText),
      ];
    }),
  ];

  const blankFields = _.filter(toCheck, _.isBlank).length;
  const result =
    blankFields > 0
      ? `There ${
          blankFields == 1 ? "is" : "are"
        } ${blankFields} unanswered question${
          blankFields == 1 ? "" : "s"
        } remaining`
      : null;

  return result;
};

const isStarted = (state) => {
  const sectionData = getSectionData(SECTION_ID, state);
  return _(sectionData).isPresent();
};

const OtherWasteProduced = ({
  onChange,
  number,
  sectionData,
  binGroupSurveys,
  onChangeForBinGroupSurvey,
}) => {
  const { packingNotesOrComment } = sectionData;

  const [errors, setErrors] = useReducer(
    (errorStates, updatedAttr) => ({
      ...errorStates,
      ...validateFields(updatedAttr),
    }),
    {}
  );

  const preamble = (
    <div>
      <p>
        Please use this form to confirm which other waste streams your site produces. For each waste stream, please indicate an estimated yearly total weight in kilograms (Kg). Also, please indicate if you are using the packaging provided by your waste contractor or other.
      </p>
    </div>
  );

  const { url } = useRouteMatch();
  const history = useHistory();
  const modalUrl = `${url}/other-waste-produced`;
  const handleOpenHelpModal = () => history.push(modalUrl);

  return (
    //  In the previous section Anenta have pre-filled most of the information
    // related to waste we know is collected from your premises. There are
    // unfortunately waste types that we do not know about which we need you to
    // provide some detail. This information is required to demonstrate
    // effective segregation even if the NHS has no responsibility for the
    // waste.
    <Section sectionId={SECTION_ID} title={TITLE} subtext="" wideBody="true" number={number}>
      <ModalRoute
        path={modalUrl}
        parentPath={url}
        className="an-modal-lg react-router-modal__modal"
      >
        <OtherWasteProducedModal onClose={() => history.push(url)} />
      </ModalRoute>

      <div className="media mb-3 mt-3">
        <div className="media-body">
          {preamble}

          <h4 className="h4 mt-4 mb-3 strong">
            Non Clinical Waste Type Audit Survey
          </h4>

          {binGroupSurveys.map((binGroupSurvey) => {
            return (
              <OtherWasteProducedPerBinGroup
                onOpenHelpModal={handleOpenHelpModal}
                key={binGroupSurvey.id}
                onChange={onChangeForBinGroupSurvey(binGroupSurvey.id)}
                binGroupSurvey={binGroupSurvey}
                errors={errors[binGroupSurvey.id]}
                setErrors={(updatedAttr) =>
                  setErrors({ [binGroupSurvey.id]: updatedAttr })
                }
              />
            );
          })}

          <TextAreaInput
            name="packingNotesOrComment"
            value={packingNotesOrComment}
            label={"Packing Notes or Comment"}
            subtext="If your waste is not placed in the corresponding packaging please indicate the alternative being used."
            onChange={onChange}
            allErrors={errors}
            placeholder={"Enter Packing Notes or Comment"}
          />
        </div>
      </div>
    </Section>
  );
};

var SummaryView = ({
  wasteAudit: pawaAudit,
  binGroupSurveysForOtherWasteProduced,
}) => {
  const { packingNotesOrComment } = pawaAudit.sections[SECTION_ID] || {};

  // console.log({ binGroupSurveysForOtherWasteProduced });

  const renderedBinGroups = binGroupSurveysForOtherWasteProduced.map(
    (binGroupSurveyAndData) => {
      const {
        // data

        id,
        title,
        packaging,
        legendFillColor,

        // form fields
        totalKg,
        hasUsedDefaultPackaging,
        hasUsedOtherWastePackaging,
        otherWastePackagingUsedFreeText,

        // handles cases where form is optional
        isProduced,
        askIsProduced,
      } = binGroupSurveyAndData;

      const labelToValue = {
        [`Packaging Used`]: (
          <ul className="ml-4">
            {[
              hasUsedDefaultPackaging && packaging,
              hasUsedOtherWastePackaging && otherWastePackagingUsedFreeText,
            ]
              .filter((exists) => exists)
              .map((item) => (
                <li key={item}>{item}</li>
              ))}
          </ul>
        ),
      };

      const otherPackagingRowValue = hasUsedOtherWastePackaging
        ? otherWastePackagingUsedFreeText
        : hasUsedOtherWastePackaging;

      return (
        <div className="bordered ml-3 my-3 pl-3" key={id}>
          <h4 className="h4 mt-4 mb-3 strong d-flex">
            <div style={{ color: legendFillColor }} className="mr-1">
              &#9673;
            </div>
            {title}
          </h4>

          <div>
            {askIsProduced && (
              <SummaryRow
                value={isProduced}
                label={"Is Recycling Waste produced?"}
              />
            )}
            <SubmitSummaryRowWithValidation
              value={totalKg}
              label={"Total KG"}
              validator={(value) =>
                (askIsProduced && isProduced === 'yes') ? validateIntFieldGTE(1)({
                  fieldName: "totalKg",
                  updatedAttrs: { totalKg },
                }) : null
              }
            />
            {(!askIsProduced || isProduced === "yes") &&
              Object.entries(labelToValue).map(([key, value]) => (
                <CustomSummaryRow value={value} label={key} key={key} />
              ))}
          </div>
        </div>
      );
    }
  );

  return (
    <SubmitSummarySection sectionId={SECTION_ID} title={TITLE}>
      <div className="media">
        <div className="media-body">
          {renderedBinGroups}

          <CustomSummaryRow
            value={packingNotesOrComment}
            label={"Packing Notes or Comment"}
            notRequired
          />
        </div>
      </div>
    </SubmitSummarySection>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    sectionData: getSectionData(SECTION_ID, state),
    binGroupSurveys: selectRequiredBinGroupsForSectionId(SECTION_ID)(state),
  };
};

const mapDispatchToProps = (dispatch, {}) => {
  return {
    onChange: (value, name) => {
      dispatch(updateSectionData(SECTION_ID, d.set({}, name, value)));
    },
    onChangeForBinGroupSurvey: (bgSurveyId) => (value, name) => {
      dispatch(updateBgSurvey(bgSurveyId, d.set({}, name, value)));
    },
  };
};

const ConnectedOtherWasteProduced = connect(
  mapStateToProps,
  mapDispatchToProps
)(OtherWasteProduced);

export default {
  sectionId: SECTION_ID,
  title: TITLE,
  incompleteMessage,
  isStarted,
  Component: ConnectedOtherWasteProduced,
  SummaryView,
};
