import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import LinkIcon from "@mui/icons-material/Link";
import TagIcon from "@mui/icons-material/Tag";
import {
  Box,
  Chip,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import { Contact } from "api/tickets";
import { startCase, toLower } from "lodash";
import { useContext } from "react";
import { ReattemptVisitRequest } from "service/ticket/types";
import { formatDate } from "sharedUtils";
import { useTypedSelector } from "ticket/app/store";
import AppContext from "ticket/AppContext";
import { getTicketId } from "ticket/selectors";

interface ReattemptVisitCardProps {
  request: ReattemptVisitRequest;
  inputtedBy: Contact;
}

const CardHeader: React.FC<{ request: ReattemptVisitRequest }> = ({ request }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        "& .MuiTypography-root": {
          fontSize: "13px",
        },
      }}
    >
      <Typography sx={{ fontWeight: "700" }}>
        Reattempt Visit Request&nbsp;
      </Typography>
      {request.cancelledAt && (
        <Typography sx={{ color: "#CF2D42" }}>&nbsp;Cancelled&nbsp;</Typography>
      )}
      {!request.cancelledAt && request.confirmedAt && (
        <Typography sx={{ color: "#828B98" }}>
          &nbsp;Confirmed for&nbsp;
          <span style={{ color: "#19E53B" }}>
            {formatDate(request.reattemptDate)}
          </span>
        </Typography>
      )}
      {!request.cancelledAt && !request.confirmedAt && (
        <Typography component="span"
          sx={{
            fontSize: "13px",
            fontWeight: "700",
            color: "#FFA600",
          }}
        >
          Awaiting Date
        </Typography>
      )}
    </Box>
  );
};

const CardContent: React.FC<ReattemptVisitCardProps> = ({
  request,
  inputtedBy,
}) => {
  return (
    <>
      {request.reattemptDate ? (
        <Typography sx={{ fontSize: "13px", color: "#828B98" }}>
          Visit confirmed for{" "}
          <span style={{ color: "#19E53B" }}>
            {formatDate(request.reattemptDate)}
          </span>
          <Typography component="span" sx={{ fontSize: "11px", color: "#828B98" }}>
            {" "}
            on{" "}
            <span style={{ color: "#BFBFBF" }}>
              {formatDate(request.confirmedAt)}
            </span>{" "}
            by{" "}
            <span style={{ color: "#BFBFBF" }}>
              {inputtedBy?.nameWithoutEmail || "No Agent"}
            </span>
          </Typography>
        </Typography>
      ) : request.cancelledAt ? (
        <Typography sx={{ fontSize: "13px", color: "#828B98" }}>
          Visit cancelled on{" "}
          <span style={{ color: "red" }}>
            {formatDate(request.cancelledAt)}
          </span>{" "}
          <Typography component="span" sx={{ fontSize: "11px" }}>
            by{" "}
            <span style={{ color: "#BFBFBF" }}>
              {inputtedBy?.nameWithoutEmail || "No Agent"}
            </span>
          </Typography>
        </Typography>
      ) : (
        <Typography sx={{ fontSize: "13px", color: "#828B98" }}>
          Visit confirmed for{" "}
          <Chip
            label="Awaiting Date"
            size="small"
            component="span"
            sx={{
              background: "#5F6369",
              color: "#BFBFBF",
              fontSize: "11px",
              fontWeight: "bold",
            }}
          />
        </Typography>
      )}
    </>
  );
};

export const ActionButtons = ({ actions, isActive, request, linkSelected }) => {
  const ticketId = useTypedSelector(getTicketId);
  const { onSelectAction, setSelectedContainer } = useContext(AppContext);

  const scrollToTagInCompositeId = () => {
    const specificId = request.id;
    setSelectedContainer(specificId);

    const element = document.getElementById(specificId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      const potentialTargets = document.querySelectorAll("[id]");
      const targetsArray = Array.from(potentialTargets);
      for (let el of targetsArray) {
        if (el.id.includes(specificId)) {
          el.scrollIntoView({ behavior: "smooth", block: "center" });
          break;
        }
      }
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {isActive && (
        <>
          {actions?.map((action) => (
            <Tooltip
              arrow
              placement="top"
              title={startCase(action.value)}
              key={action.value}
            >
              <IconButton
                aria-label={action.label}
                onClick={() => onSelectAction(null, { path: action.path })}
                sx={{ py: 0 }}
              >
                {toLower(action.label) === "confirm" ? (
                  <CheckOutlinedIcon sx={{ color: "#AAB2BD" }} />
                ) : (
                  <HighlightOffOutlinedIcon sx={{ color: "#AAB2BD" }} />
                )}
              </IconButton>
            </Tooltip>
          ))}
        </>
      )}
      {ticketId === request?.linkedTicketIds[0] ? (
        <Tooltip arrow placement="top" title="Go to reply">
          <IconButton
            onClick={scrollToTagInCompositeId}
            sx={{
              ...(linkSelected && {
                border: "1px solid #6c8c89",
              }),
            }}
          >
            <LinkIcon
              sx={{
                color: "#AAB2BD",
                ...(linkSelected && {
                  fill: "#6c8c89",
                }),
              }}
            />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip arrow placement="top" title="Go to ticket">
          <IconButton
            onClick={() =>
              window.open(
                `/admin/tickets/${request?.linkedTicketIds[0]}/app`,
                "_blank"
              )
            }
          >
            <TagIcon
              sx={{
                color: "#AAB2BD",
                ...(linkSelected && {
                  fill: "#6c8c89",
                }),
              }}
            />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

const ReattemptVisitRequestCard = {
  CardHeader,
  CardContent,
  ActionButtons,
};

export default ReattemptVisitRequestCard;
