import PropTypes from "prop-types";
import NotesSection from "./common/NotesSection";
import { ModelErrors } from "sharedComponents";

const RequestReattemptDateForm = ({
  form,
  context,
  errors,
  onSetForm,
}) => {

  const { serviceName } = context;

  return (
    <>
      <NotesSection
        form={form}
        onSetForm={onSetForm}
        context={context}
        contactLabel="Requested By"
        contactPlaceholder="Who requested the reattempt date?"
        dateLabel="Received On"
        dateValue={form?.requestedAt}
        onChange={(newValue) =>
          onSetForm({
            requestedAt: newValue.format("DD/MM/YYYY"),
          })
        }
      />

      <ModelErrors errors={errors} />
    </>
  );
};

RequestReattemptDateForm.propTypes = {
  form: PropTypes.object.isRequired,
  errors: PropTypes.object,
  context: PropTypes.shape({
    serviceName: PropTypes.string.isRequired,
    serviceUuid: PropTypes.string.isRequired,
  }),
  onSetForm: PropTypes.func.isRequired,
};

export default RequestReattemptDateForm;
