import { CheckboxInput, NumberInput, TextInput, YesNoInput } from 'auditComponents';

const Checkbox = CheckboxInput.Checkbox;

// adrLabel: null
// adrLabelImageUrl: null
// hazCodes: ""
// id: 138
// legendBorderColor: "#000000"
// legendFillColor: "#000000"
// packaging: "Black bags or other packaging provided by your contractor"
// title: "Domestic Waste EWC 20 01 39/20 03 07/20 01 08/20 03 99/20 01 11/20 01 99/20 03 01/20 01 10/20 01 01/20 01 40/20 03 02/20 01 02"
// totalKg: ""
export const OtherWasteProducedPerBinGroup = ({
  binGroupSurvey,
  onChange,
  onOpenHelpModal,
  errors,
  setErrors,
}) => {
  const {
    // data
    id,
    title,
    packaging,
    svgHtml,

    // form fields
    totalKg,
    hasUsedDefaultPackaging,

    hasUsedOtherWastePackaging,
    otherWastePackagingUsedFreeText,

    // handles cases where form is optional
    isProduced,
    askIsProduced,
  } = binGroupSurvey;

  console.log({ binGroupSurvey });

  return (
    <>
      <h5 className={`h5 mt-4 mb-3 strong d-flex id-${id}`}>
        <div
          className="mr-1"
          dangerouslySetInnerHTML={{ __html: svgHtml }}
        ></div>
        {title}
      </h5>
      <div className="panel-body">
        <div className="p-4 mb-4 bordered relative">
          {askIsProduced && (
            <YesNoInput
              className="mr-4 pr-4"
              name="isProduced"
              value={isProduced}
              label={"Is Recycling Waste produced?"}
              onChange={onChange}
            />
          )}

          {(!askIsProduced || isProduced === "yes") && (
            <div>
              <button
                onClick={onOpenHelpModal}
                className="absolute"
                style={{
                  borderRadius: "50%",
                  height: 30,
                  width: 30,
                  right: 20,
                  top: 10,
                }}
              >
                ?
              </button>

              <div className="pr-4 mr-4">
                <NumberInput
                  name="totalKg"
                  subtext={
                    binGroupSurvey.title === "Domestic Waste" ? (
                      <span>
                        Please indicate an estimated annual weight (Kg) and indicate the packaging used from the options presented. The annual (kg) cannot be 1. Should you have any issues in determining this please contact your general waste supplier who will assist you.
                      </span>
                    ) : (
                      <span>
                        Please indicate an estimated annual weight (Kg) and indicate the packaging used from the options presented. The annual (kg) cannot be 1. Should you have any issues in determining this please contact your recycling supplier who will assist you.
                      </span>
                    )
                  }
                  value={totalKg}
                  label={"Estimated Annual Kgs"}
                  onChange={onChange}
                  allErrors={errors}
                  onValidate={setErrors}
                  placeholder={"Enter total KG"}
                />
              </div>

              <CheckboxInput label="Packaging Used">
                { _.isPresent(packaging) && <Checkbox
                  value={hasUsedDefaultPackaging}
                  name={"hasUsedDefaultPackaging"}
                  label={packaging}
                  onSelect={onChange}
                />
                }

                <Checkbox
                  value={hasUsedOtherWastePackaging}
                  name={"hasUsedOtherWastePackaging"}
                  label={"Other"}
                  onSelect={onChange}
                />
              </CheckboxInput>

              {hasUsedOtherWastePackaging && (
                <TextInput
                  name="otherWastePackagingUsedFreeText"
                  value={otherWastePackagingUsedFreeText}
                  label={""}
                  onChange={onChange}
                  placeholder={"Enter other packaging used"}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
