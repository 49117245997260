import { Box, Chip, Typography } from "@mui/material";
import { startCase } from "lodash";
import { formatDate } from "sharedUtils";
import LogActionButton from "service/forms/VoidFormDialog";


const TagHeader = ({ request }) => {
  const { reattemptDate } = request.data || {};
  const isCancelled = request.name === "ReattemptDateRequestCancelled";
  const isConfirmed = reattemptDate && request.name === "ReattemptDateConfirmed";

  return (
    <Box>
      {isCancelled
        ? startCase(request.name)
        : isConfirmed
        ? `${startCase(request.name)} for `
        : `${startCase(request.name)} `
      }

      {isConfirmed ? (
        <Chip
          size="xsmall"
          color="success"
          label={formatDate(request?.data?.reattemptDate)}
        />
      ) : null}
    </Box>
  );
};

const TagContent = ({ request }) => {
  const { notes } = request.data || {};

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box></Box>
        <LogActionButton event={request} />
      </Box>
      {notes && (
        <Typography variant="h6" sx={{ my: 1 }}>
          {notes}
        </Typography>
      )}
    </>
  );
};

export default { TagHeader, TagContent };
