import { Box, Chip, Typography } from "@mui/material";
import { formatDate } from "sharedUtils";
import React from "react";
import { ServiceResumptionRequest } from "service/ticket/types";
import { Contact } from "api/tickets";

const CardHeader: React.FC<{ request: ServiceResumptionRequest }> = ({
  request,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        "& .MuiTypography-root": {
          fontSize: "13px",
        },
      }}
    >
      <Typography fontWeight="700">
        Service Resumption Request&nbsp;
      </Typography>
      {request.cancelledAt && (
        <Typography sx={{ color: "#CF2D42" }}>Cancelled</Typography>
      )}
      {!request.cancelledAt && request.confirmedAt && (
        <Typography sx={{ color: "#828B98" }}>
          &nbsp;Confirmed&nbsp;
          <span style={{ color: "#19E53B" }}>
            {formatDate(request?.date)}
          </span>
        </Typography>
      )}
      {!request.cancelledAt && !request.confirmedAt && (
        <Typography
          component="span"
          sx={{
            fontSize: "13px",
            fontWeight: "700",
            color: "#FFA600",
          }}
        >
          Awaiting Date
        </Typography>
      )}
    </Box>
  );
};

const CardContent: React.FC<{
  request: ServiceResumptionRequest;
  inputtedBy: Contact;
}> = ({ request, inputtedBy }) => {
  return (
    <Box
      sx={{
        "& .MuiTypography-root": {
          fontSize: "13px",
          color: "#828B98",
        },
      }}
    >
      <Typography>
        {`Vendor has been requested to resume the service after ${formatDate(
          request.requestedVisitsExpectedAfter
        )}`}
      </Typography>
      {request?.cancelledAt ? (
        <Typography>
          Service Resumption Cancelled on{" "}
          <span style={{ color: "red" }}>
            {formatDate(request?.cancelledAt)}
          </span>{" "}
          <Typography component="span" sx={{ fontSize: "11px" }}>
            by{" "}
            <span style={{ color: "#BFBFBF" }}>
              {inputtedBy?.nameWithoutEmail || "No Agent"}
            </span>
          </Typography>
        </Typography>
      ) : request?.confirmedAt ? (
        <Typography>
          Resumption from
          <span style={{ color: "#19E53B" }}>
            {` ${formatDate(request?.visitsExpectedAfter)}`}
          </span>
          {" Confirmed on "}
          <span style={{ color: "#BFBFBF" }}>
            {formatDate(request.confirmedAt)}
          </span>
          {" by "}
          <span style={{ color: "#BFBFBF" }}>
            {inputtedBy?.nameWithoutEmail || "No Agent"}
          </span>
        </Typography>
      ) : (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography>Resumption From</Typography>
          <Chip
            label="Awaiting Date"
            size="small"
            sx={{
              background: "#5F6369",
              color: "#BFBFBF",
              fontSize: "11px",
              fontWeight: "bold",
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default { CardHeader, CardContent };
