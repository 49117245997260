import { Box, TableCell, TableRow, Typography } from "@mui/material";
import { map, size, startCase } from "lodash";
import BinDot from "service/BinDot";
import LogActionButton from "service/forms/VoidFormDialog";
import AdhocVisitRequestCardDetails, {
  getRequestType,
  getTypographyLabel,
} from "service/report-request-box/AdhocVisitRequestCardDetails";
import { formatDate, useBinFetch } from "sharedUtils";

function getLabelShort(requestType) {
  switch (requestType) {
    case "clearAllAndDeliver":
      return "clear all and deliver additional stock";
    case "clearAll":
      return "clear all";
    case "collectAndDeliver":
      return "collect specific bins and deliver additional stock";
    case "collect":
      return "collect specific bins only";
    case "deliver":
      return "only deliver additional stock";
    default:
      return "";
  }
}

export const TagHeader = ({ request }) => {
  const { cancelledAt, date, deliveryUnits } = request.data || {};
  const isCancelled = cancelledAt;
  const isConfirmed = date && request.name === "AdhocVisitRequestConfirmed";

  let clearAll;
  let collectionUnits;
  if (request?.data?.collectionUnits === "all") {
    clearAll = true;
    collectionUnits = [];
  } else {
    clearAll = false;
    collectionUnits = request?.data?.collectionUnits;
  }

  const isCollect = size(collectionUnits) > 0;
  const isDelivery = size(deliveryUnits) > 0;
  const isCollectAndDelivery = isCollect && isDelivery;
  const requestType = getRequestType({
    clearAll,
    isCollectAndDelivery,
    isCollect,
    isDelivery,
  });

  const labelShort = getLabelShort(requestType);

  return (
    <>
      {isCancelled
        ? startCase(request.name)
        : isConfirmed
        ? `${startCase(request.name)} for ${formatDate(date)}`
        : `${startCase(request.name)} `}
      {labelShort}
    </>
  );
};

export const TagContent = ({ request }) => {
  const { binTypes, binGroups } = useBinFetch();
  const { deliveryUnits, notes } = request.data || {};

  let clearAll;
  let collectionUnits;
  if (request?.data?.collectionUnits === "all") {
    clearAll = true;
    collectionUnits = [];
  } else {
    clearAll = false;
    collectionUnits = request?.data?.collectionUnits;
  }

  const isCollect = size(collectionUnits) > 0;
  const isDelivery = size(deliveryUnits) > 0;
  const isCollectAndDelivery = isCollect && isDelivery;

  const requestType = getRequestType({
    clearAll,
    isCollectAndDelivery,
    isCollect,
    isDelivery,
  });

  const label = getTypographyLabel(requestType);

  const deliveryRows = map(deliveryUnits, ({ serviceCode, quantity }) => {
    const binType = binTypes[serviceCode];
    const binGroup = binGroups[binType?.binGroupId];

    return (
      <TableRow
        key={serviceCode}
        sx={{
          "& .MuiTableCell-root": {
            borderTop: "1px solid rgba(255, 255, 255, 0.2)",
            borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
          },
        }}
      >
        <TableCell scope="row">
          <BinDot
            binGroup={binGroup}
            tooltipName={true}
            tooltipPlace="top"
            showImg
            {...binType}
          />
        </TableCell>
        <TableCell>{binType?.name}</TableCell>
        <TableCell>{serviceCode}</TableCell>
        <TableCell>{quantity}</TableCell>
      </TableRow>
    );
  });

  const collectionRows = map(
    collectionUnits,
    ({ serviceCode, quantity, visitActionId }) => {
      const binType = binTypes[serviceCode];
      const binGroup = binGroups[binType?.binGroupId];

      return (
        <TableRow
          key={serviceCode}
          sx={{
            "& .MuiTableCell-root": {
              borderTop: "1px solid rgba(255, 255, 255, 0.2)",
              borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
            },
          }}
        >
          <TableCell scope="row">
            <BinDot
              binGroup={binGroup}
              tooltipName={true}
              tooltipPlace="top"
              showImg
              {...binType}
            />
          </TableCell>
          <TableCell>{binType?.name}</TableCell>
          <TableCell>{serviceCode}</TableCell>
          <TableCell>{quantity}</TableCell>
          <TableCell>{startCase(visitActionId)}</TableCell>
        </TableRow>
      );
    }
  );

  const collectAndDeliver = collectionUnits?.reduce((acc, unit) => {
    acc[unit?.serviceCode] = { ...unit };
    return acc;
  }, {});

  deliveryUnits?.forEach((deliveryUnit) => {
    if (collectAndDeliver[deliveryUnit?.serviceCode]) {
      collectAndDeliver[deliveryUnit?.serviceCode].deliveryQty =
        deliveryUnit?.quantity;
    } else {
      collectAndDeliver[deliveryUnit?.serviceCode] = {
        serviceCode: deliveryUnit?.serviceCode,
        deliveryQty: deliveryUnit?.quantity,
      };
    }
  });

  const collectAndDeliveryRows = map(
    collectAndDeliver,
    ({ serviceCode, quantity, visitActionId, deliveryQty }) => {
      const binType = binTypes[serviceCode];
      const binGroup = binGroups[binType?.binGroupId];

      return (
        <TableRow
          key={serviceCode}
          sx={{
            "& .MuiTableCell-root": {
              borderTop: "1px solid rgba(255, 255, 255, 0.2)",
              borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
            },
          }}
        >
          <TableCell scope="row">
            <BinDot
              binGroup={binGroup}
              tooltipName={true}
              tooltipPlace="top"
              showImg
              {...binType}
            />
          </TableCell>
          <TableCell>{binType?.name}</TableCell>
          <TableCell>{serviceCode}</TableCell>
          <TableCell>{quantity}</TableCell>
          <TableCell>{deliveryQty}</TableCell>
          <TableCell>{startCase(visitActionId)}</TableCell>
        </TableRow>
      );
    }
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>{label}</Box>
        <LogActionButton event={request} />
      </Box>

      {(isCollect || isDelivery) && (
        <AdhocVisitRequestCardDetails
          clearAll={clearAll}
          isCollectAndDelivery={isCollectAndDelivery}
          isCollect={isCollect}
          isDelivery={isDelivery}
          deliveryRows={deliveryRows}
          collectionRows={collectionRows}
          collectAndDeliveryRows={collectAndDeliveryRows}
        />
      )}
      {notes && (
        <Typography variant="h6" sx={{ my: 1 }}>
          {notes}
        </Typography>
      )}
    </>
  );
};

export default { TagHeader, TagContent };
