import actions from './actions'
import { getCurrentSpecification, getUnitSpecifications } from '../specifications/selectors'
import { getService } from '../services/selectors'

const newBookingForService = (serviceId) => async (dispatch, getState) => {
  const state = getState()
  const service = getService(serviceId, state)
  const locationId = state.locationId
  const ticketId = state.api.ticketId

  // Call the notification endpoint
  try {
    await fetch(`/admin/locations/${locationId}/notify_migration_candidate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ ticket_id: ticketId, id: locationId, service_action: 'Book Ad-hoc Collection' })
    })
  } catch (error) {
    console.error('Failed to send migration candidate notification:', error)
  }

  const specification = getCurrentSpecification(serviceId, state)
  var flatUnitSpecifications = getUnitSpecifications(specification)
  dispatch(actions.newBooking(serviceId, flatUnitSpecifications))
}

export {
  newBookingForService
}
