import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import LinkIcon from "@mui/icons-material/Link";
import TagIcon from "@mui/icons-material/Tag";
import {
  Box,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Contact } from "api/tickets";
import { map, size, startCase, toLower } from "lodash";
import { useContext, useMemo } from "react";
import BinDot from "service/BinDot";
import { AdhocVisitRequest, BinGroup, BinType } from "service/ticket/types";
import { formatDate } from "sharedUtils";
import { useTypedSelector } from "ticket/app/store";
import AppContext from "ticket/AppContext";
import { getTicketId } from "ticket/selectors";
import {
  getRequestType,
  getTypographyLabel,
} from "./AdhocVisitRequestCardDetails";

interface AdhocVisitCardProps {
  request: AdhocVisitRequest;
  binTypes: Record<string, BinType>;
  binGroups: Record<string, BinGroup>;
  inputtedBy: Contact;
}

const CardHeader: React.FC<{ request: AdhocVisitRequest }> = ({ request }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        "& .MuiTypography-root": {
          fontSize: "13px",
        },
      }}
    >
      <Typography sx={{ fontWeight: "700" }}>
        Adhoc Visit Request&nbsp;
      </Typography>
      {request.cancelledAt && (
        <Typography sx={{ color: "#CF2D42" }}>&nbsp;Cancelled&nbsp;</Typography>
      )}
      {!request.cancelledAt && request.confirmedAt && (
        <Typography sx={{ color: "#828B98" }}>
          &nbsp;Confirmed for&nbsp;
          <span style={{ color: "#19E53B" }}>
            {formatDate(request?.date)}
          </span>
        </Typography>
      )}
      {!request.cancelledAt && !request.confirmedAt && (
        <Typography
          component="span"
          sx={{
            fontWeight: "700",
            color: "#FFA600",
          }}
        >
          Awaiting Date
        </Typography>
      )}
    </Box>
  );
};

const CardLabel: React.FC<{ request: AdhocVisitRequest }> = ({ request }) => {
  const { deliveryUnits } = request || {};
  const clearAll = request?.clearAll;
  const collectionUnits = clearAll ? [] : request?.collectionUnits;

  const isCollect = size(collectionUnits) > 0;
  const isDelivery = size(deliveryUnits) > 0;
  const isCollectAndDelivery = isCollect && isDelivery;
  const requestType = getRequestType({
    clearAll,
    isCollectAndDelivery,
    isCollect,
    isDelivery,
  });
  const label = getTypographyLabel(requestType);
  return <>{label}</>;
};

const CardContent: React.FC<AdhocVisitCardProps> = ({
  request,
  binTypes,
  binGroups,
  inputtedBy,
}) => {
  const { deliveryUnits } = request || {};

  const clearAll = request?.clearAll;
  const collectionUnits = clearAll ? [] : request?.collectionUnits;

  const isCollect = size(collectionUnits) > 0;
  const isDelivery = size(deliveryUnits) > 0;
  const isCollectAndDelivery = isCollect && isDelivery;

  const requestType = getRequestType({
    clearAll,
    isCollectAndDelivery,
    isCollect,
    isDelivery,
  });
  const headers = useMemo(() => {
    switch (requestType) {
      case "clearAllAndDeliver":
        return ["", "Deliver Additional", "Code"];
      case "collect":
        return ["", "Collect", "Action"];
      case "collectAndDeliver":
        return ["", "Collect", "Deliver Additional", "Action"];
      case "deliver":
        return ["", "Deliver Additional", "Code"];
      default:
        return ["", "Collect", "Deliver", "Action"];
    }
  }, [requestType]);

  const collectAndDeliver = collectionUnits?.reduce((acc, unit) => {
    acc[unit?.serviceCode] = { ...unit };
    return acc;
  }, {});

  deliveryUnits?.forEach((deliveryUnit) => {
    if (collectAndDeliver[deliveryUnit?.serviceCode]) {
      collectAndDeliver[deliveryUnit?.serviceCode].deliveryQty =
        deliveryUnit?.quantity;
    } else {
      collectAndDeliver[deliveryUnit?.serviceCode] = {
        serviceCode: deliveryUnit?.serviceCode,
        deliveryQty: deliveryUnit?.quantity,
      };
    }
  });

  const newRows = map(
    collectAndDeliver,
    ({
      serviceCode,
      quantity: collectionQuantity,
      visitActionId,
      deliveryQty: deliveryQuantity,
    }) => {
      const binType = binTypes && binTypes[serviceCode];
      const binGroup = binGroups && binGroups[binType?.binGroupId];

      return (
        <TableRow key={serviceCode}>
          <TableCell
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <BinDot
              binGroup={binGroup}
              tooltipName={true}
              tooltipPlace="top"
              {...binType}
            />{" "}
            <Typography>{binType?.name}</Typography>
          </TableCell>

          {["collect", "collectAndDeliver"].includes(requestType) && (
            <TableCell>
              {collectionQuantity === "all" ? "All" : collectionQuantity}
            </TableCell>
          )}

          {["clearAllAndDeliver", "collectAndDeliver", "deliver"].includes(
            requestType
          ) && <TableCell>{deliveryQuantity || 0}</TableCell>}

          <TableCell
            sx={{
              color: "#5F6369 !important",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {visitActionId ? <span>{startCase(visitActionId)}</span> : null}

            <span>{serviceCode}</span>
          </TableCell>
        </TableRow>
      );
    }
  );

  return (
    <>
      {newRows.length > 0 && (
        <AdhocCardTable rows={newRows} headers={headers} />
      )}

      {request?.date ? (
        <Typography sx={{ fontSize: "13px", color: "#828B98" }}>
          Visit confirmed for{" "}
          <span style={{ color: "#19E53B" }}>{formatDate(request?.date)}</span>
          <Typography
            component="span"
            sx={{ fontSize: "11px", color: "#828B98" }}
          >
            {" "}
            on{" "}
            <span style={{ color: "#BFBFBF" }}>
              {formatDate(request?.confirmedAt)}
            </span>{" "}
            by{" "}
            <span style={{ color: "#BFBFBF" }}>
              {inputtedBy?.nameWithoutEmail || "No Agent"}
            </span>
          </Typography>
        </Typography>
      ) : request?.cancelledAt ? (
        <Typography sx={{ fontSize: "13px", color: "#828B98" }}>
          Visit cancelled on{" "}
          <span style={{ color: "red" }}>
            {formatDate(request?.cancelledAt)}
          </span>{" "}
          <Typography component="span" sx={{ fontSize: "11px" }}>
            by{" "}
            <span style={{ color: "#BFBFBF" }}>
              {inputtedBy?.nameWithoutEmail || "No Agent"}
            </span>
          </Typography>
        </Typography>
      ) : (
        <Typography sx={{ fontSize: "13px", color: "#828B98" }}>
          Visit confirmed for{" "}
          <Chip
            label="Awaiting Date"
            size="small"
            component="span"
            sx={{
              background: "#5F6369",
              color: "#BFBFBF",
              fontSize: "11px",
              fontWeight: "bold",
            }}
          />
        </Typography>
      )}
    </>
  );
};

export const AdhocCardTable = ({ rows, headers }) => {
  return (
    <TableContainer>
      <Table
        sx={{
          "& .MuiTableCell-root": {
            border: "none",
            padding: 0,
          },
        }}
      >
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell
                key={index}
                sx={{ color: "#5F6369", fontSize: "11px" }}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            "& .MuiTableCell-root": {
              fontSize: "11px",
              color: "#BFBFBF",
            },
          }}
        >
          {rows}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const ActionButtons = ({ actions, isActive, request, linkSelected }) => {
  const ticketId = useTypedSelector(getTicketId);
  const { onSelectAction, setSelectedContainer } = useContext(AppContext);
  const scrollToTagInCompositeId = () => {
    const specificId = request.id;
    setSelectedContainer(specificId);

    const element = document.getElementById(specificId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      const potentialTargets = document.querySelectorAll("[id]");
      const targetsArray = Array.from(potentialTargets); // Convert NodeList to Array
      for (let el of targetsArray) {
        if (el.id.includes(specificId)) {
          el.scrollIntoView({ behavior: "smooth", block: "center" });
          break;
        }
      }
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {isActive && (
        <>
          {actions?.map((action) => (
            <Tooltip
              arrow
              placement="top"
              title={startCase(action.value)}
              key={action.value}
            >
              <IconButton
                aria-label={action.label}
                onClick={() => onSelectAction(null, { path: action.path })}
                sx={{ py: 0 }}
              >
                {toLower(action.label) === "confirm" ? (
                  <CheckOutlinedIcon sx={{ color: "#AAB2BD" }} />
                ) : (
                  <HighlightOffOutlinedIcon sx={{ color: "#AAB2BD" }} />
                )}
              </IconButton>
            </Tooltip>
          ))}
        </>
      )}
      {ticketId === request?.linkedTicketIds[0] ? (
        <Tooltip arrow placement="top" title="Go to reply">
          <IconButton
            onClick={scrollToTagInCompositeId}
            sx={{
              ...(linkSelected && {
                border: "1px solid #6c8c89",
              }),
            }}
          >
            <LinkIcon
              sx={{
                color: "#AAB2BD",
                ...(linkSelected && {
                  fill: "#6c8c89",
                }),
              }}
            />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip arrow placement="top" title="Go to ticket">
          <IconButton
            onClick={() =>
              window.open(
                `/admin/tickets/${request?.linkedTicketIds[0]}/app`,
                "_blank"
              )
            }
          >
            <TagIcon
              sx={{
                color: "#AAB2BD",
                ...(linkSelected && {
                  fill: "#6c8c89",
                }),
              }}
            />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default { CardHeader, CardLabel, CardContent };
