import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinkIcon from "@mui/icons-material/Link";
import {
  Box,
  Collapse,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useGetLocationRemediationQuery } from "api/services";
import { camelCase, mapKeys } from "lodash";
import { useContext, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DebugButton } from "service/GlobalDebugger";
import {
  AdhocVisitRequest,
  FailedVisitReport,
  ReattemptVisitRequest,
  RemediationNoticeRequest,
  ServiceReinstatementRequest,
  ServiceResumptionRequest,
  ServiceSuspensionRequest,
  ServiceTerminationRequest,
  StockDeliveryRequest,
} from "service/ticket/types";
import { RemediationIcon } from "service/utils/Icons";
import { formatDate } from "sharedUtils";
import AppContext from "ticket/AppContext";
import { getLocationId, useStakeholderContact } from "ticket/selectors";
import AdhocVisitRequestCardWrapper from "./AdhocVisitRequestCard.wrapper";
import RemediationCardWrapper from "./RemediationCard.wrapper";
import ReportCardWrapper from "./ReportCard.wrapper";
import ServiceReinstatementRequestCardWrapper from "./ServiceReinstatementRequestCard.wrapper";
import ServiceResumptionRequestCardWrapper from "./ServiceResumptionRequestCard.wrapper";
import ServiceSuspensionRequestCardWrapper from "./ServiceSuspensionRequestCard.wrapper";
import ServiceTerminationCardWrapper from "./ServiceTerminationCard.wrapper";
import StockDeliveryCardWrapper from "./StockDeliveryCard.wrapper";
import ReattemptVisitCardWrapper from "./ReattemptVisitCard.wrapper";

const requestComponents = {
  Adhoc: AdhocVisitRequestCardWrapper,
  Termination: ServiceTerminationCardWrapper,
  Reinstatement: ServiceReinstatementRequestCardWrapper,
  Reattempt: ReattemptVisitCardWrapper,
  Stock: StockDeliveryCardWrapper,
  Remediation: RemediationCardWrapper,
  FailedVisit: ReportCardWrapper,
  Suspension: ServiceSuspensionRequestCardWrapper,
  Resumption: ServiceResumptionRequestCardWrapper,
};

export const CardHolder: React.FC<{
  request:
    | AdhocVisitRequest
    | ReattemptVisitRequest
    | StockDeliveryRequest
    | RemediationNoticeRequest
    | FailedVisitReport
    | ServiceTerminationRequest
    | ServiceSuspensionRequest
    | ServiceResumptionRequest
    | ServiceReinstatementRequest;
}> = ({ request }) => {
  const [open, setOpen] = useState(true);
  const getRequestComponent = (requestType: string) => {
    const componentKey = Object.keys(requestComponents).find((key) =>
      requestType?.includes(key)
    );
    return componentKey ? requestComponents[componentKey] : null;
  };

  const SpecificRequestComponent = getRequestComponent(request?.type);

  if (!SpecificRequestComponent) return null;

  const {
    currentTicketId,
    highlightedIds,
    setHighlightedIds,
    onOpenRemediation,
    setRequestedAtDate,
    setEventId,
    setRequestType,
    setServiceUuid,
  } = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const locationId = getLocationId();

  const remediationData = useGetLocationRemediationQuery(locationId, {
    skip: !locationId,
  }).data?.remediation;
  const remediation = remediationData
    ? mapKeys(remediationData, (_, k) => camelCase(k))
    : null;

  const isSelected = useMemo(() => {
    return (
      request?.replyIds.length > 0 &&
      request?.replyIds?.every((id) => highlightedIds?.includes(String(id)))
    );
  }, [highlightedIds]);

  const isInCurrentPath =
    currentTicketId === String(request?.linkedTicketIds[0]);

  const inputtedBy = useStakeholderContact(request?.inputtedById);

  const toggleHighlightedReplies = () => {
    const searchParams = new URLSearchParams(location.search);

    if (String(request?.linkedTicketIds[0]) === currentTicketId) {
      // We're on the same ticket
      let newHighlights = [];

      if (isSelected) {
        // If this request is already selected, deselect it
        newHighlights = [];
      } else {
        // If this request is not selected, select it and deselect others
        newHighlights = [...request.replyIds];
      }

      // Update search params
      searchParams.delete("highlight");
      if (newHighlights.length > 0) {
        newHighlights.forEach((id) => searchParams.append("highlight", id));
      }

      setHighlightedIds(newHighlights);

      // Update the URL and push to history
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    } else {
      // We are not on the same ticket
      const ticketId = request?.linkedTicketIds[0];
      const queryParam = `highlight=${request?.replyIds.join("&highlight=")}`;
      const newTabUrl = `/admin/locations/${locationId}/app#/tickets/${ticketId}?${queryParam}`;
      window.open(newTabUrl, "_blank");
    }
  };
  return (
    <Box
      sx={{
        background: "#1E1E1E",
        color: "#BFBFBF",
        borderTop: "1px solid #656D78",
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          pl: "10px",
          minHeight: "40px",
          backgroundColor: open ? "#121212" : "inherit",
          borderBottom: "0px dashed #4B4B4B",
          borderWidth: open ? "1px" : "0",
        }}
      >
        <Typography
          sx={{ fontSize: "13px", fontWeight: "700", color: "#BFBFBF" }}
        >
          <SpecificRequestComponent.CardHeader request={request} />
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& .MuiSvgIcon-root": {
              fontSize: "14px",
              color: "#5F6369",
            },
          }}
        >
          {isInCurrentPath ? (
            <>
              <IconButton onClick={() => toggleHighlightedReplies()}>
                <LinkIcon
                  sx={{
                    color: isSelected ? "#4FC1E9 !important" : "###5F6369",
                  }}
                />
              </IconButton>
              {SpecificRequestComponent.CardExtraLinks && (
                <SpecificRequestComponent.CardExtraLinks request={request} />
              )}
            </>
          ) : (
            <Link
              onClick={() => toggleHighlightedReplies()}
              underline="none"
              sx={{
                fontSize: "11px",
                cursor: "pointer",
                color: "#5F6369",
                "&:hover": {
                  ...(isInCurrentPath ? { color: "#5F6369" } : {}),
                },
              }}
            >
              #{request?.linkedTicketIds[0]}
            </Link>
          )}
          {currentTicketId === String(request?.linkedTicketIds[0]) &&
            (!remediation ||
              remediation.status == "accepted" ||
              remediation.status == "cancelled") && (
              <IconButton
                onClick={() => {
                  setRequestedAtDate(request?.requestedAt);
                  setEventId(request?.id);
                  setRequestType(request?.type);
                  setServiceUuid(request?.serviceUuid);
                  onOpenRemediation();
                }}
              >
                <RemediationIcon />
              </IconButton>
            )}
          <IconButton>
            <DebugButton {...request} />
          </IconButton>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
      </Stack>
      {SpecificRequestComponent.CardLabel && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: open ? "5px 10px 5px 10px" : "0px 10px 10px 10px",
          }}
        >
          <Typography
            sx={{ fontSize: "13px", fontWeight: "400", color: "#BFBFBF" }}
          >
            <SpecificRequestComponent.CardLabel request={request} />
          </Typography>

          {open && (
            <Typography sx={{ textAlign: "right", fontSize: "11px" }}>
              Requested on <strong> {formatDate(request?.requestedAt)} </strong>{" "}
              by
              <strong> {inputtedBy?.nameWithoutEmail || "No Agent"} </strong>
            </Typography>
          )}
        </Box>
      )}

      <Collapse in={open} timeout="auto" unmountOnExit>
        {!SpecificRequestComponent.CardLabel && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: open ? "5px 10px 5px 10px" : "0px 10px 10px 10px",
            }}
          >
            <div></div>

            {open && (
              <Typography sx={{ textAlign: "right", fontSize: "11px" }}>
                Requested on{" "}
                <strong> {formatDate(request?.requestedAt)} </strong> by
                <strong> {inputtedBy?.nameWithoutEmail || "No Agent"} </strong>
              </Typography>
            )}
          </Box>
        )}
        <Box
          sx={{
            m: "6px 10px",
            padding: "6px",
            border: "1px solid #4B4B4B",
            borderTop: "0px dashed #4B4B4B",
            borderBottom: "0px dashed #4B4B4B",
            backgroundColor: open ? "#121212" : "inherit",
            borderWidth: open ? "1px" : "0",
          }}
        >
          <SpecificRequestComponent.CardContent request={request} />
        </Box>
      </Collapse>
    </Box>
  );
};
