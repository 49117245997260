import { ReattemptVisitRequest } from "service/ticket/types";
import { useStakeholderContact } from "ticket/selectors";
import ReattemptVisitCard from "./ReattemptVisitCard";

const CardHeader: React.FC<{ request: ReattemptVisitRequest }> = ({ request }) => {
  return <ReattemptVisitCard.CardHeader request={request} />;
};

const CardContent: React.FC<{ request: ReattemptVisitRequest }> = ({ request }) => {
  const inputtedBy = useStakeholderContact(request?.inputtedById);

  return (
    <ReattemptVisitCard.CardContent
      request={request}
      inputtedBy={inputtedBy}
    />
  );
};

export default { CardHeader, CardContent };
