import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Collapse, Divider, IconButton } from "@mui/material";
import TextSmall from "components/TextSmall";
import { flatMap } from "lodash";
import { useState } from "react";
import SpecChangeReplyTag from "service/request-logs/SpecChangeReplyTag";
import { formatDate } from "sharedUtils";
import { useTicket } from "ticket/selectors";
import AdhocVisitReplyTag from "./AdhocVisitReplyTag";
import FailedVisitReplyTag from "./FailedVisitReplyTag";
import VendorFeedbackReplyTag from "./VendorFeedbackReplyTag";
import ProcessedManuallyTag from "./ProcessedManuallyTag";
import ReattemptDateReplyTag from "./ReattemptDateRequestReplyTag";
import RemediationReplyTag from "./RemediationReplyTag";
import ServiceReinstatementReplyTag from "./ServiceReinstatementReplyTag";
import ServiceResumptionReplyTag from "./ServiceResumptionReplyTag";
import ServiceSuspensionReplyTag from "./ServiceSuspensionReplyTag";
import ServiceTerminationReplyTag from "./ServiceTerminationReplyTag";
import StockDeliveryReplyTag from "./StockDeliveryReplyTag";
import VisitFrequencyChangeReplyTag from "./VisitFrequencyChangeReplyTag";
import MarkAsResponseRequiredReplyTag from "./MarkAsResponseRequiredReplyTag";
import ReplyAcceptedAsResponseTag from "./ReplyAcceptedAsResponseTag";

const replyTagsComponents = {
  AdhocVisitRequest: AdhocVisitReplyTag,
  FailedVisitReport: FailedVisitReplyTag,
  SpecChangeRequest: SpecChangeReplyTag,
  VisitFrequencyChangeRequest: VisitFrequencyChangeReplyTag,
  StockDeliveryRequest: StockDeliveryReplyTag,
  ServiceTerminationRequest: ServiceTerminationReplyTag,
  ServiceReinstatementRequest: ServiceReinstatementReplyTag,
  ServiceSuspensionRequest: ServiceSuspensionReplyTag,
  ServiceResumptionRequest: ServiceResumptionReplyTag,
  VendorFeedbackOnVisitFailureRecieved: VendorFeedbackReplyTag,
  ClientFeedbackOnVisitFailureRecieved: null,
  Reattempt: ReattemptDateReplyTag,
  RemediationSent: RemediationReplyTag,
  RemediationResponseRejected: RemediationReplyTag,
  RemediationCancelled: RemediationReplyTag,
  RemediationReplyLogged: RemediationReplyTag,
  ProcessedManually: ProcessedManuallyTag,
  ReplyMarkedAsResponseExpected: MarkAsResponseRequiredReplyTag,
  ReplyAcceptedAsResponse: ReplyAcceptedAsResponseTag,
};

const ReplyTag = ({ eventData, url }) => {
  const { stakeholderContacts } = useTicket() || {};
  const allStakeholders = flatMap(stakeholderContacts || [], (x) => x);
  const [openStates, setOpenStates] = useState({});

  const getLogComponent = (eventName) => {
    const componentName = Object.keys(replyTagsComponents).find((name) =>
      eventName.includes(name),
    );
    return componentName ? replyTagsComponents[componentName] : null;
  };

  const toggleOpen = (index) => {
    setOpenStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <>
      {eventData.map((event, index) => {
        const inputtedBy = allStakeholders?.find(
          (x) => x.id === event?.metadata?.inputtedById,
        );
        const SpecificLogComponent = getLogComponent(event.name);

        if (!SpecificLogComponent) return null;

        return (
          <>
            <Divider
              variant="middle"
              sx={{
                m: 0,
                mx: -2,
                borderColor: "#fff",
                opacity: "0.2",
              }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: SpecificLogComponent.TagContent ? "pointer" : "auto",
                px: 1,
                backgroundColor: openStates[index] ? "#121212" : "inherit",
                borderBottom: "0px dashed #4B4B4B",
                borderWidth: openStates[index] ? "1px" : "0",
              }}
              onClick={
                SpecificLogComponent.TagContent ? () => toggleOpen(index) : null
              }
            >
              <SpecificLogComponent.TagHeader request={event} />
              <IconButton
                disableRipple
                sx={{
                  color: "#fff",
                  visibility: SpecificLogComponent.TagContent
                    ? "inherit"
                    : "hidden",
                }}
              >
                {openStates[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Box>
            <Collapse in={openStates[index]} timeout={0} unmountOnExit>
              <Box
                sx={{
                  m: "6px 0 10px 8px",
                  px: "10px",
                }}
              >
                <SpecificLogComponent.TagContent request={event} url={url} />
                {inputtedBy && (
                  <Box sx={{ mt: "5px", textAlign: "right" }}>
                    <TextSmall>
                      Inputted by{" "}
                      <strong>{inputtedBy?.nameWithoutEmail}</strong> on{" "}
                      <strong>{formatDate(event?.createdAt)}</strong>
                    </TextSmall>
                  </Box>
                )}
              </Box>
            </Collapse>
          </>
        );
      })}
    </>
  );
};

export default ReplyTag;
