import { Box } from "@mui/material";
import NotesSection from "./common/NotesSection";
import { ModelErrors } from "sharedComponents";
import { formatDate } from "sharedUtils";

const CancelReattemptDateRequestForm = ({
  requestedAt,
  form,
  context,
  errors,
  onSetForm,
}) => {
  const { serviceName } = context;

  return (
    <>
      <Box sx={{ marginTop: 1 }}>We requested Re-attempt visit date on {formatDate(requestedAt)}</Box>
      <NotesSection
        form={form}
        onSetForm={onSetForm}
        context={context}
        contactLabel="Cancelled By"
        contactPlaceholder="Who cancelled the reattempt date request?"
        dateLabel="Cancelled On"
        dateValue={form?.cancelledAt}
        onChange={(newValue) =>
          onSetForm({
            cancelledAt: newValue.format("DD/MM/YYYY"),
          })
        }
      />

      <ModelErrors errors={errors} />
    </>
  );
};

export default CancelReattemptDateRequestForm;
