import actions from './actions'
import { getCurrentSpecification } from '../specifications/selectors'
import { getService } from '../services/selectors'
//import _ from 'lodashExtended'
//
//
const newDeliveryForService = (serviceId) => async(dispatch, getState) => {
  console.log('Beta Test: Agent clicked Bin Delivery')
  const state = getState()

  var flatUnitSpecifications = {}

  //const specification = getCurrentSpecification(serviceId, state)

  //if(specification) {
    //_.each(specification.collectionGroups, ({unitSpecifications}) => {
      //_.each(unitSpecifications, ({binTypeId, quantity}) => {
        //flatUnitSpecifications[binTypeId] = { binTypeId, quantity }
      //})
    //})
  //}
  const service = getService(serviceId, state)
  const locationId = state.locationId
  const ticketId = state.api.ticketId

  // Call the notification endpoint
  try {
    await fetch(`/admin/locations/${locationId}/notify_migration_candidate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        ticket_id: ticketId,
        id: locationId,
        service_action: 'Request Bin Delivery'
      })
    })
  } catch (error) {
    console.error('Failed to send migration candidate notification:', error)
  }

  dispatch(actions.newDelivery(serviceId, flatUnitSpecifications))
}


export {
  newDeliveryForService
}
