import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux';
//
import ServiceHolder from './ServiceHolder'
import OrderLink from '../OrderLink'
import IssueBox from '../IssueBox'
import IssueBoxSection from '../IssueBoxSection'
import NewServiceDeliveryOption from '../NewServiceDeliveryOption'

import CollectionPlanSelect from '../CollectionPlanSelect'
import CollectionPlan from '../CollectionPlan'

import UnitSpecificationList from '../UnitSpecificationList'
import EditSpecificationModal from '../EditSpecificationModal'
import EditProductSubscriptionModal from '../EditProductSubscriptionModal'
import ProductSpecificationList from '../ProductSpecificationList'
import ProductSubscriptionConfirmDate from '../ProductSubscriptionConfirmDate'
import NewServiceDeliveryDate from '../NewServiceDeliveryDate'
import SpecificationConfirmDate from '../SpecificationConfirmDate'
import VendorConfirmedOnDate from '../VendorConfirmedOnDate'

import FinalCollectionDate from '../FinalCollectionDate'
import FinalCollectionOption from '../FinalCollectionOption'

import CollectionDaysSelect from '../CollectionDaysSelect'
import CollectionDays from '../CollectionDays'

import NextCollectionDateSelect from '../NextCollectionDateSelect'
import NextCollectionDate from '../NextCollectionDate'

import MarkProcessedBtn from '../MarkProcessedBtn'
import ServiceActionMenu from '../ServiceActionMenu'
import MissedCollectionReportList from '../MissedCollectionReportList'

import { markServiceProcessed, cancelChangeSpecification } from '../../services/operations'
import terminateServiceIssuesActions from '../../terminateServiceIssues/actions'
import { isServiceProcessed, isServiceResolved, isServiceClosed } from '../../services/selectors'
import { getCurrentSpecification } from '../../specifications/selectors'
import { getCurrentProductSubscription } from '../../productSubscriptions/selectors'
import { getTerminateServiceIssue } from '../../terminateServiceIssues/selectors'
import { getNewServiceIssue } from '../../newServiceIssues/selectors'
import { getNextRoutineCollection } from '../../nextRoutineCollections/selectors'
import _ from 'lodashExtended';


const NewServiceIssue = ({title, id, processed, resolved, vendorAccount, specification, productSubscription, newServiceIssue, onMarkProcessed}) => {

  var productSubscriptionUuid, productSpecifications
  if(productSubscription) {
    var { uuid: productSubscriptionUuid, productSpecifications } = productSubscription
  }

  return(
    <IssueBox title='New Service Request'>

     <IssueBoxSection>

        <OrderLink {...newServiceIssue.order}/>

        {specification ? _.map(specification.collectionGroups, (collectionGroup) => {
          return <IssueBoxSection><div key={collectionGroup.uuid} className="mb5">

            <div className="mb5">
              Collected <CollectionPlanSelect collectionGroup={collectionGroup}/> <CollectionDaysSelect collectionGroup={collectionGroup}/>
            </div>

            { collectionGroup.hasRoutineCollections ?
              <div className="mb5">
                <span>with the first collection on <NextCollectionDateSelect collectionGroup={collectionGroup}/></span>
              </div>
             : null }

            <div className="mb5">
              <div className="mb3">Unit Specification:</div>
              <div style={{ marginLeft: 10 }}>
                <EditSpecificationModal specificationUuid={specification.uuid}>
                  <UnitSpecificationList unitSpecifications={collectionGroup.unitSpecifications}/>
                </EditSpecificationModal>
              </div>
            </div>

          </div></IssueBoxSection>

        }) :
          <UnknownSpecification serviceId={id}/>
        }

        <IssueBoxSection>
          <div className="mb5">
            <NewServiceDeliveryOption newServiceIssue={newServiceIssue}/>
          </div>

          {!!newServiceIssue.noDelivery ? null :
            <div className="mb5">Bin delivery confirmed for <NewServiceDeliveryDate newServiceIssue={newServiceIssue}/></div>
          }


          <div className="mb5 text-muted">Vendor Account: {vendorAccount.name}</div>

          { specification ?
          <div className="mb5">
            <SpecificationConfirmDate specificationUuid={specification.uuid}></SpecificationConfirmDate>
          </div> : null }

        </IssueBoxSection>

      </IssueBoxSection>

      <IssueBoxSection>
        <MarkProcessedBtn processed={processed} resolved={resolved} onClick={onMarkProcessed} />
      </IssueBoxSection>


    </IssueBox>
  )

}

const UpdateCollectionDate = ({title, id, processed, resolved, specification, onMarkProcessed}) => {
  return(
    <IssueBox title="Update Collection Date">

        {_.map(specification.collectionGroups, (collectionGroup) => {
          return <div key={collectionGroup.uuid} ><IssueBoxSection>

            <div className="mb5">
              Collected <CollectionPlan collectionGroup={collectionGroup} /> <CollectionDaysSelect collectionGroup={collectionGroup}/>
            </div>
            <div style={{ marginLeft: 10 }}>
              <UnitSpecificationList unitSpecifications={collectionGroup.unitSpecifications}/>
            </div>
            { collectionGroup.hasRoutineCollections ?
              <div className="mb5">
                Next collection is <NextCollectionDateSelect collectionGroup={collectionGroup}/>
              </div>
            : null }

          </IssueBoxSection></div>
        })}

      <IssueBoxSection>
        <MarkProcessedBtn processed={processed} resolved={resolved} onClick={onMarkProcessed} />
      </IssueBoxSection>
    </IssueBox>
  )
}

const SpecificationChange = ({ title, id, processed, resolved, specification, onMarkProcessed, onCancelChangeSpec }) => {
  return(
    <IssueBox title="Change unit and or frequency specification issue"
      handleDelete={ specification.id ? null : onCancelChangeSpec }>
      {_.map(specification.collectionGroups, (collectionGroup) => {
        return (<div key={collectionGroup.uuid}><IssueBoxSection>
          <div className="mb5">Collected<CollectionPlanSelect collectionGroup={collectionGroup}/>
            <CollectionDaysSelect collectionGroup={collectionGroup}/>
          </div>
          <div className="mb5">
            <div className="mb3">Unit Specification:</div>
            <div style={{ marginLeft: 10 }}>
              <EditSpecificationModal specificationUuid={specification.uuid}>
                <UnitSpecificationList unitSpecifications={collectionGroup.unitSpecifications}/>
              </EditSpecificationModal>
            </div>
          </div>
          <div className="mb5"><SpecificationConfirmDate specificationUuid={specification.uuid}></SpecificationConfirmDate></div>

          { collectionGroup.hasRoutineCollections ? (
            <div className="mt10 text-muted">
              Next collection on<NextCollectionDateSelect strongTarget={false} collectionGroup={collectionGroup}/>
            </div>
          ) : null }

        </IssueBoxSection></div>)
      })}
      <IssueBoxSection>
        <MarkProcessedBtn processed={processed} resolved={resolved} onClick={onMarkProcessed} />
      </IssueBoxSection>
    </IssueBox>
  )
}

const TerminateServiceIssue = ({title, id, processed, resolved, terminateServiceIssue, onCancelTermination, onMarkProcessed}) => {

  return(
    <IssueBox title='Terminate Service' handleDelete={ terminateServiceIssue.id ? null : onCancelTermination }>
        <div className="mb5">
          <FinalCollectionOption terminateServiceIssue={terminateServiceIssue}/>
          {!!terminateServiceIssue.noFinalCollection ?
            <span>Service terminated, no final collection required</span>
          :
            <span>Final collection scheduled on <FinalCollectionDate terminateServiceIssue={terminateServiceIssue}></FinalCollectionDate></span>
          }
        </div>

        <IssueBoxSection>
          <MarkProcessedBtn processed={processed} resolved={resolved} onClick={onMarkProcessed} />
        </IssueBoxSection>
    </IssueBox>
  )

}

const Closed = ({title, id, processed, vendorAccount, specification, newServiceIssue, onMarkProcessed}) => {
  return(
    <IssueBox heading="Current Specification" historical={true}>
        {specification ? _.map(specification.collectionGroups, (collectionGroup) => {
          return <div key={collectionGroup.uuid}>
            <div className="mb5">
              Collected <strong className="text-primary"><CollectionPlan collectionGroup={collectionGroup} /></strong>
              <CollectionDays collectionGroup={collectionGroup}/>
            </div>


            <div className="mb5">{ collectionGroup.hasRoutineCollections ? <span>Next collection on <span className="text-success"><NextCollectionDate collectionGroup={collectionGroup}/></span></span> : "Adhoc Collections - Non Booked" }</div>

            <div className="mb10">
              <div className="mb5 text-muted">Unit Specification</div>
              <div className="mb5 ml10">
                 <UnitSpecificationList unitSpecifications={collectionGroup.unitSpecifications}/>
              </div>
            </div>


          </div>
        }) : <UnknownSpecification serviceId={id}/>}
    </IssueBox>
  )
}

const SpecificationAndSubscriptionChange = ({title, id, processed, resolved, productSubscription, specification, onMarkProcessed}) => {
  return(
    <IssueBox title='Change Specification and Subscription'>
      <IssueBoxSection>
        <div>Dear Vendor</div>
        <div>Please change the specification to the following:</div>
        {_.map(specification.collectionGroups, (collectionGroup) => {
          return <div key={collectionGroup.uuid} >
            Collect <CollectionPlanSelect collectionGroup={collectionGroup}/>
            <EditSpecificationModal specificationUuid={specification.uuid} >
              <UnitSpecificationList unitSpecifications={collectionGroup.unitSpecifications}/>
            </EditSpecificationModal>
          </div>
          })}
          {
            productSubscription ?
              <div>
                <div>Service monthly the following</div>
                <div>
                  <EditProductSubscriptionModal uuid={productSubscription.uuid}>
                    <ProductSpecificationList productSpecifications={productSubscription.productSpecifications}/>
                  </EditProductSubscriptionModal>
                </div>
              </div> : null
          }
          <div>Please <VendorConfirmedOnDate productSubscriptionUuid={productSubscription.uuid} specificationUuid={specification.uuid}>confirm this has been processed</VendorConfirmedOnDate> as well as the next collection date{_.keys(specification.collectionGroups).length > 1 ? 's' : ''}</div>
        </IssueBoxSection>
      <IssueBoxSection>
        <div>Dear Client</div>
        <div>Your service specification has been amended: </div>
        {_.map(specification.collectionGroups, (collectionGroup) => {
          return <div key={collectionGroup.uuid} >
            Collected <CollectionPlanSelect collectionGroup={collectionGroup}/> <CollectionDaysSelect collectionGroup={collectionGroup}/>
            with the next collection on <NextCollectionDateSelect collectionGroup={collectionGroup}/>
            <EditSpecificationModal specificationUuid={specification.uuid} >
              <UnitSpecificationList unitSpecifications={collectionGroup.unitSpecifications}/>
            </EditSpecificationModal>
          </div>
          })}
        {
          productSubscription ?
            <div>
              <div>You will also now have the following serviced monthly:</div>
              <div>
                <EditProductSubscriptionModal uuid={productSubscription.uuid}>
                  <ProductSpecificationList productSpecifications={productSubscription.productSpecifications}/>
                </EditProductSubscriptionModal>
              </div>

            </div> : null
        }
      </IssueBoxSection>
      <IssueBoxSection>
        <MarkProcessedBtn processed={processed} resolved={resolved} onClick={onMarkProcessed} />
      </IssueBoxSection>
    </IssueBox>
  )
}

const SubscriptionChange = ({title, id, processed, resolved, productSubscription, specification, onMarkProcessed}) => {
  return(
    <IssueBox title='Change Subsription'>
      <IssueBoxSection>
        <div>Dear Vendor</div>
          <div>
            <div>Please change this locations service to be</div>
            <div>
              <EditProductSubscriptionModal uuid={productSubscription.uuid}>
                <ProductSpecificationList productSpecifications={productSubscription.productSpecifications}/>
              </EditProductSubscriptionModal>
            </div>
          </div>
          <div>and continue to service of:</div>
          {_.map(specification.collectionGroups, (collectionGroup) => {
            return <div key={collectionGroup.uuid} >
              Collect <CollectionPlan collectionGroup={collectionGroup}/>
              <UnitSpecificationList unitSpecifications={collectionGroup.unitSpecifications}/>
          </div>
          })}
          <div>Please <ProductSubscriptionConfirmDate productSubscription={productSubscription}>confirm this has been processed</ProductSubscriptionConfirmDate></div>
        </IssueBoxSection>
      <IssueBoxSection>
        <div>Dear Client</div>

        <div>
          <div>Your new monthly service will be:</div>
          <div>
            <EditProductSubscriptionModal uuid={productSubscription.uuid}>
              <ProductSpecificationList productSpecifications={productSubscription.productSpecifications}/>
            </EditProductSubscriptionModal>
          </div>

        </div>
        <div>Your other collections will continue as: </div>
        {_.map(specification.collectionGroups, (collectionGroup) => {
          return <div key={collectionGroup.uuid} >
            Collected <CollectionPlan collectionGroup={collectionGroup}/> <CollectionDays collectionGroup={collectionGroup}/>
            with the next collection on <NextCollectionDate collectionGroup={collectionGroup}/>
              <UnitSpecificationList unitSpecifications={collectionGroup.unitSpecifications}/>
          </div>
          })}
      </IssueBoxSection>
      <IssueBoxSection>
        <MarkProcessedBtn processed={processed} resolved={resolved} onClick={onMarkProcessed} />
      </IssueBoxSection>
    </IssueBox>
  )
}



let WashroomService = (props) => {
  let serviceNode, menuItems

  if(props.terminateServiceIssue) {
    serviceNode = <TerminateServiceIssue {...props}/>
      menuItems = [
        'reportMissedCollection',
      ]
  } else if(props.newServiceIssue) {
    serviceNode = <NewServiceIssue {...props}/>
      menuItems = [
        'reportMissedCollection',
        'changeSpecification',
        'bookAdhocCollection',
        'terminateService'
      ]
  } else if(!props.specification.closed && (props.productSubscription && !props.productSubscription.closed)){
    serviceNode = <SpecificationAndSubscriptionChange {...props}/>
      menuItems = [
        'reportMissedCollection',
        'updateCollectionDate',
        'terminateService'
      ]
  } else if(!props.specification.closed){
    serviceNode = <SpecificationChange {...props}/>
      menuItems = [
        'reportMissedCollection',
        'changeProductSubscription',
        'terminateService'
      ]
  } else if(props.productSubscription && !props.productSubscription.closed){
    serviceNode = <SubscriptionChange {...props}/>
      menuItems = [
        'reportMissedCollection',
        'changeSpecification',
        'terminateService'
      ]
  } else if(!_.every(props.nextRoutineCollections, 'closed') ){
    serviceNode = <UpdateCollectionDate {...props}/>
      menuItems = [
        'reportMissedCollection',
        'changeSpecification',
        'changeProductSubscription',
        'terminateService'
      ]
  } else {
    serviceNode = <Closed {...props} />
      menuItems = [
        'reportMissedCollection',
        'changeSpecification',
        'changeProductSubscription',
        'updateCollectionDate',
        'terminateService'
      ]
  }


  return(<ServiceHolder {...props} menuItems={menuItems}>
    {serviceNode}
    <MissedCollectionReportList serviceId={props.id}/>
  </ServiceHolder>)

}

WashroomService.propTypes = {
  specification: PropTypes.shape({
    uuid: PropTypes.string,
    id: PropTypes.number,
    collectionGroups: PropTypes.object.isRequired
  }).isRequired,
  productSubscription: PropTypes.shape({
    uuid: PropTypes.string,
    productSpecifications: PropTypes.object.isRequired
  }),
  currentSpecificationVersionUuid: PropTypes.string,
  id: PropTypes.number.isRequired,
  vendorAccount: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  onMarkProcessed: PropTypes.func.isRequired
}

const mapStateToProps = (state, {id}) => {
  const specification = getCurrentSpecification(id, state)
  const productSubscription = getCurrentProductSubscription(id, state)

  return {
    processed: isServiceProcessed(id, state),
    resolved: isServiceResolved(id, state),
    closed: isServiceClosed(id, state),
    specification,
    productSubscription,
    newServiceIssue: getNewServiceIssue(id, state),
    terminateServiceIssue: getTerminateServiceIssue(id, state),
    nextRoutineCollections: _.map(specification.collectionGroups, (cg) => getNextRoutineCollection(cg, state)),
  }
}

const mapDispatchToProps = (dispatch, {id}) => {
  return {
    onMarkProcessed: (processed) => dispatch(markServiceProcessed(id, processed)),
    onCancelTermination: () => dispatch(terminateServiceIssuesActions.cancelTerminateService(id)),
    onCancelChangeSpec: () => dispatch(cancelChangeSpecification(id))
  }
}

WashroomService = connect(
  mapStateToProps,
  mapDispatchToProps
)(WashroomService)

export default WashroomService
